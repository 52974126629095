import * as c from '../components/landing';
import '../styles/landing.scss';

function Landing() {
    return (
        <div className="landing">
            <div className="container">
                <c.Blur />
                <c.Header />
            </div>
            <div className="wave top layer"></div>
                <c.Work />
            <div className="wave bottom layer"></div>
            <c.Volunteering />
            <c.Skills />
        </div>
    );
}

export default Landing;
