import React from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import LogoIsarRanger from '../../assets/work/isarRanger_logo.svg'
import LogoTTT from '../../assets/work/ttt_logo.svg'
import LogoOWS from '../../assets/work/ows_logo.svg'
import LogoEnorati from '../../assets/work/enorati_logo.png'

function Volunteering() {
    return (
        <div id="volunteering">
            <div className="container">
                <h3 className='sectionTitle'>Volunteering</h3>
                <div className="work-grid">
                    <div className="work-item">
                        <div className="icon">
                            <img src={ LogoIsarRanger } alt="IsarRanger Logo" />
                        </div>
                        <div className="content">
                            <h4 className="title">IsarRanger</h4>
                            <p className="desc">I am active as an organizer and co-founder of the IsarRanger organization. My responsibility is to bring people together in both small and large groups to collectively shape local flora and fauna.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Volunteering