import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Landing from "./views/Landing";
import './styles/main.scss';

function App() {
  return (
    <>
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="*" element={<Landing />} />
        </Routes>
    </>
  );
}

export default App;
