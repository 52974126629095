import React from 'react'
import { Link } from 'react-router-dom'
import { RiMenu4Line } from 'react-icons/ri'
import '../styles/navbar.scss'

function Navbar() {
    return (
        <div className="container">
            <nav className='navbar'>
                <div className="logo">
                    <p>Ferdi<span className="accent">.</span></p>
                </div>
                <ul>
                    <li>
                        <a href='/'>Start</a>
                    </li>
                    <li>
                        <a href='/#work'>Work</a>
                    </li>
                    <li>
                        <a href='/#skills'>Skills</a>
                    </li>
                </ul>
                <div className="burgerMenu"><RiMenu4Line /></div>
            </nav>
        </div>

    )
}

export default Navbar