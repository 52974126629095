import React from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import LogoPlutorix from '../../assets/work/plutorix_logo.svg'
import LogoTTT from '../../assets/work/ttt_logo.svg'
import LogoOWS from '../../assets/work/ows_logo.svg'
import LogoEnorati from '../../assets/work/enorati_logo.png'

function Work() {
    return (
        <div id="work">
            <div className="container">
                <h3 className='sectionTitle'>Work</h3>
                <p className='sectionSubTitle'>
                    I'm a full-stack developer with a passion for creating
                    beautiful, intuitive, and responsive user interfaces.
                </p>
                <div className="work-grid">
                    <div className="work-item">
                        <div className="icon">
                            <img src={ LogoPlutorix } alt="Plutorix Logo" />
                        </div>
                        <div className="content">
                            <h4 className="title">Plutorix<AiOutlineCheckCircle className='check' /></h4>
                            <p className="desc">I am working with plutorix, in the position of co-founder, mainly in the field of web development. Plutorix focuses on opening doors and creating new ways to reach more customers for companies that previously had only a small presence on the Internet.</p>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="icon">
                            <img src={ LogoTTT } alt="TicTacToe Logo" />
                        </div>
                        <div className="content">
                            <h4 className="title">TicTacToe Discord Bot</h4>
                            <p className="desc">With our TicTacToe Discord Bot, we create communities of all sizes the possibility to play the old known game TicTacToe in real time. The bot is used by thousands of players every day and has reached millions of players all over the world.</p>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="icon">
                            <img src={ LogoEnorati } alt="Enorati Logo" />
                        </div>
                        <div className="content">
                            <h4 className="title">Enorati - Tool for Teachers</h4>
                            <p className="desc">Enorati started as a vacation project, but quickly evolved into a tool that allows teachers to prepare and plan their lessons in a revolutionary way. The tool provides you with cloud storage for all your documents and can be integrated beautifully into any lesson.</p>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="icon">
                            <img src={ LogoOWS } alt="OWS Logo" />
                        </div>
                        <div className="content">
                            <h4 className="title">OneWordStory Discord Bot</h4>
                            <p className="desc">With OneWordStory members of a Discord server can write stories together. The trick is that you can only write one word at a time, then it's the next player's turn. This leads to very funny and entertaining stories.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Work