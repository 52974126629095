import React from 'react'
import HeaderImageWP from '../../assets/header.webp'
import { BsGithub, BsLinkedin } from 'react-icons/bs'

function Header() {

    const badgeText = 'More Info - More Info - '

    return (
        <header id='header'>
            <div className="mainHeadCol">
                <h1 className='pageTitle'>Ferdinand<br /><span className="accent">Duschka</span></h1>
                <h2 className="subtitle">I am a self taught software developer with several years of experience and several successfully completed projects! Currently I am involved in building a start up company, which is active in the IT industry.</h2>
                { /*  <div className="badge">
                    <p>
                        {new Array(badgeText.length).fill(0).map((_, i) => <span key={i} className={`char${i}`}>{ badgeText[i] }</span>)}
                    </p>
                </div>*/}
                <div className="social">
                    <a target={'_blank'} href="https://www.linkedin.com/in/ferdinand-duschka-803619229/" className="btn">
                        <BsLinkedin />
                        <p>LinkedIn</p>
                    </a>
                    <a target={'_blank'} href="https://github.com/fduschka" className="btn">
                        <BsGithub />
                        <p>GitHub</p>
                    </a>
                </div>
            </div>
            <div className="header-image">
                <img src={HeaderImageWP} alt="Ferdi Duschka" className='bg' />
                <picture className='graphic'>
                    <img src={HeaderImageWP} />
                </picture>
            </div>
        </header >
    )
}

export default Header