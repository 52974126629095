import React from 'react'
import { AiFillHtml5 } from 'react-icons/ai'
import { IoLogoNodejs } from 'react-icons/io'
import { DiCss3, DiReact, DiMongodb } from 'react-icons/di'
import { RiFlutterFill } from 'react-icons/ri'
import { FaAws } from 'react-icons/fa'
import FigmaLogo from '../../assets/skills/figma.svg'
import AWSLogo from '../../assets/skills/aws.svg'

function Skills() {
    return (
        <div id='skills'>
            <div className='container'>
                <h3 className="sectionTitle">My Skills</h3>
                <div className="skill-list">
                    <div className="skill">
                        <div className="icon"><IoLogoNodejs style={{ color: '#3c873a' }} /></div>
                        <p className="name">NodeJS</p>
                    </div>
                    <div className="skill">
                        <div className="icon"><AiFillHtml5 style={{ color: '#e34c26' }} /></div>
                        <p className="name">HTML</p>
                    </div>
                    <div className="skill">
                        <div className="icon"><DiCss3 style={{ color: '#2965f1' }} /></div>
                        <p className="name">CSS</p>
                    </div>
                    <div className="skill">
                        <div className="icon"><DiReact style={{ color: '#61DBFB' }} /></div>
                        <p className="name">React</p>
                    </div>
                    <div className="skill">
                        <div className="icon"><RiFlutterFill style={{ color: '#61DBFB' }} /></div>
                        <p className="name">Flutter</p>
                    </div>
                    <div className="skill">
                        <div className="icon"><img src={AWSLogo} alt="AWS Logo" /></div>
                        <p className="name">AWS</p>
                    </div>
                    <div className="skill">
                        <div className="icon"><img src={FigmaLogo} alt="Figma Logo" /></div>
                        <p className="name">Figma</p>
                    </div>
                    <div className="skill">
                        <div className="icon"><DiMongodb style={{ color: '#4DB33D' }} /></div>
                        <p className="name">Mongo</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills